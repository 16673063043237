* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  height: 100%;
  width: 100%;
  height: 100%;
  overflow: auto;
}

body {
  font-size: 1.9rem;
  font-weight: 400;
  width: 100%;
  height: 100%;
  font-family: "Assistant", sans-serif;
  line-height: 1.2;
  position: relative;
  overflow: scroll;
  background-color: #d9dde4;
  /* scroll-snap-points-y: repeat(1%);
  scroll-snap-align: center;
  scroll-snap-type: y mandatory; */
}
.light-bold {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.section--fallout {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.section--fallout nav {
  background-color: #1a293c;
  height: 10%;
  display: flex;
  align-items: start;
  justify-content: center;
}
.section--fallout nav img {
  width: 15%;
}

.section--fallout .main--content {
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 2.4rem;
}

.block {
  background-color: #1a293c;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
}

.temporary--warning .email:link,
.temporary--warning .email:visited,
.temporary--warning .email:hover,
.temporary--warning .email:active {
  text-decoration: underline;
}

a:link,
a:visited,
a:hover,
a:active {
  color: #1a293c;
}

.hidden {
  display: none !important;
}

.overview-component section {
  position: relative;
  height: 100vh;
  width: 100%;
}

.overview-component .scroll-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  letter-spacing: 0.1rem;
  white-space: nowrap;
  filter: brightness(100%);
  opacity: 1;
  transition: opacity 250ms, filter 200ms;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 15;
  padding: 2% 0;
}

.ph {
  font-size: 6.4rem;
}

.icon--down-arrow {
  color: #fff;
  font-size: 5.2rem;
}
