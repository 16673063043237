* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
  overflow: auto;
}

body {
  background-color: #d9dde4;
  width: 100%;
  height: 100%;
  font-family: Assistant, sans-serif;
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 1.2;
  position: relative;
  overflow: scroll;
}

.light-bold {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.section--fallout {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.section--fallout nav {
  background-color: #1a293c;
  justify-content: center;
  align-items: start;
  height: 10%;
  display: flex;
}

.section--fallout nav img {
  width: 15%;
}

.section--fallout .main--content {
  color: #333;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  height: 50%;
  font-size: 2.4rem;
  display: flex;
}

.block {
  background-color: #1a293c;
  width: 100%;
  height: 10%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.temporary--warning .email:link, .temporary--warning .email:visited, .temporary--warning .email:hover, .temporary--warning .email:active {
  text-decoration: underline;
}

a:link, a:visited, a:hover, a:active {
  color: #1a293c;
}

.hidden {
  display: none !important;
}

.overview-component section {
  width: 100%;
  height: 100vh;
  position: relative;
}

.overview-component .scroll-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: .1rem;
  white-space: nowrap;
  filter: brightness();
  opacity: 1;
  cursor: pointer;
  user-select: none;
  z-index: 15;
  padding: 2% 0;
  transition: opacity .25s, filter .2s;
}

.ph {
  font-size: 6.4rem;
}

.icon--down-arrow {
  color: #fff;
  font-size: 5.2rem;
}
/*# sourceMappingURL=home.940ac65d.css.map */
